@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Quicksand:wght@300;400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: "Quicksand", sans-serif;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* .login-form styling */
.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.login-form div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0;
}

label {
  font-weight: bold;
  margin-right: 10px;
  width: 75px;
}

input[type="email"],
input[type="password"] {
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 200px;
}

button[type="submit"] {
  background-color: #4caf50;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  border: none;
  margin-top: 1rem;
  cursor: pointer;
}

/* Image styling */
.shatterlogo {
  width: 100px;
  margin-bottom: 1rem;
  margin-top: 50px;
}

/* DASHBOARD */

.dashboard-container {
  display: flex;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

.dashboard-content {
  width: 80%;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
}

.dashboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dashboard h2 {
  margin-bottom: 1rem;
}

.dashboard p {
  margin-bottom: 1rem;
}

.dashboard form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  width: 100%;
}

.dashboard label {
  margin-bottom: 0.5rem;
  width: 90%;
}

.dashboard input,
.dashboard textarea {
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid gray;
  width: 95%;
}

.dashboard button[type="submit"] {
  background-color: green;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  margin-top: 1rem;
}

.dashboard textarea {
  resize: vertical;
  min-height: 100px;
  max-height: 300px;
  overflow: auto;
}

/* NAVBAR */
.nav {
  justify-content: space-between;
  color: blue;
  display: flex;
  padding: 15px;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
}

.nav h1 {
  margin: auto;
}
.nav p {
  margin-right: 10px;
  text-decoration: none;
  color: white;
  padding: 5px;
  cursor: pointer;
  user-select: none;
}
.cases-nav-links {
  color: blue;
}
.models-div {
  display: flex;
  overflow-x: scroll;
}
.models-div p {
  margin: 5px;
  border: 1px solid black;
  padding: 5px;
  cursor: pointer;
  user-select: none;
}

/* PHONE CASES */
.phone-cases-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 20px;
}

.phone-card-div {
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  padding: 44px;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  transition: transform 0.3s ease-in-out;
  width: 5rem;
  height: 15rem;
  padding-top: 0.3rem;
}

.phone-card-div:before {
  content: "";
  position: absolute;
  top: -20px;
  left: -20px;
  right: -20px;
  bottom: -20px;
  border: 2px solid #e0e0e0;
  border-radius: 20px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.phone-card-div:hover {
  transform: translateY(-10px);
}

.phone-card-div:hover:before {
  opacity: 1;
}

.phone-card-div img {
  height: auto;
  margin-bottom: 20px;
}

.phone-card-div p:first-child {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #555555;
}

.case-description {
  font-size: 8px;
  color: #888888;
}

.price-description {
  font-size: 14px;
  font-weight: bold;
  color: #444444;
}

.phone-card-div p:last-child {
  color: #bbbbbb;
  margin-top: 10px;
}

/* CASES NAV START  */

.phone-header {
  color: #3b5998; /* use any blueish color you like */
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 50px;
  border: 2px solid #3b5998; /* border color matches text color */
  padding: 20px;
  background-color: #f7f7f7; /* use any light background color you like */
}

.models-div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1c2331;
  border-radius: 10px;
  flex-direction: column;
  padding-bottom: 2rem;
}

.models-div h4 {
  margin-right: 10px;
  font-size: 24px;
  color: #fff;
}

.models-div select {
  padding: 10px;
  border-radius: 5px;
  border: none;
  font-size: 18px;
  background-color: #2e3d57;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  background-position: right 8px top 50%;
  cursor: pointer;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
  width: 100%;
  max-width: 400px;
  margin-top: 20px;
  color: #fff;
}

.models-div select:hover {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
}

.models-div select:focus {
  outline: none;
  box-shadow: 0 0 3px #0077ff;
}

.models-div select option {
  background-color: #2e3d57;
  color: #fff;
}

@media only screen and (max-width: 768px) {
  .models-div {
    max-width: 100%;
  }
}

.color-div {
  padding: 10rem;
}

/* CASES NAV END */

.color-div {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0;
  margin-bottom: 3rem;
  background-color: black;
  color: white;
  height: 4rem;
  padding-left: 1rem;
}

.color-div label {
  font-size: 1.2rem;
  white-space: nowrap;
}

.color-div select {
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.2s ease-in-out;
}

.color-div select:hover,
.color-div select:focus {
  outline: none;
  box-shadow: 0 0 0 2px #2684ff;
}

/* BUSINESS CARD CONTAINER START  */
.business-card-full-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.business-card-container {
  width: 100vw;
  border: 1px solid black;
}

.buy-hours-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 60%;
  padding: 0.4rem;
}
.buy-hours-div p {
  white-space: nowrap;
}
.buy-fix-p {
  font-weight: bold;
  font-size: 3vw;
}
.mall-p {
  font-weight: bold;
  color: #ff69b4;
  font-size: 3vw;
}
.customer-service-p {
  font-weight: bold;
  color: #d32f2f;
  font-size: 3vw;
  text-align: center;
}
.number-p {
  font-weight: bold;
  font-size: 3vw;
  text-align: center;
  color: blue;
  text-decoration: underline;
}
.logo-div {
  display: flex;
}
.email-p {
  font-weight: bold;
  color: #4682b4;
  font-size: 3vw;
}
.fa {
  padding: 20px;
  font-size: 3vw;
  width: 3vw;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
}

.fa:hover {
  opacity: 0.7;
}

.fa-facebook {
  background: #3b5998;
  color: white;
}
.fa-instagram {
  background: #125688;
  color: white;
}

.fa-snapchat-ghost {
  background: #fffc00;
  color: white;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.available-p {
  font-size: 2vw;
}
.prof-tech-p {
  font-size: 3vw;
}
.shatter-header-p {
  background-color: #3498db;
  color: #fff;
  padding: 20px;
  text-align: center; 
  font-size: 25px;
  font-weight: b;
}
.business-card-container {
  background-image: url("./images/android-iphone-repair.jpg");
  background-size: cover;
  background-position: bottom;
  position: relative;
  background-size: 45vw;
  background-repeat: no-repeat;
}
.guarentee-div {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;

}
.insideBusiness {
  display: flex;
}
.guarentee-img {
  width: 26vw;
}
.guarentee-div p {
  font-size: 3vw;

}
.certified-p {
  text-align: center;
}
.cases-button {
  margin: 1rem;
  display: inline-block;
  border-radius: 30px;
  background-color: #4B9CE2;
  color: #fff;
  font-size: 18px;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.cases-button:hover {
  background-color: #3477C1;
  cursor: pointer;
}

/* BUSINESS CARD CONTAINER END */
